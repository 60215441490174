import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { NavigationPaths } from '../interfaces/NavigationPaths.enum';
import { Role } from '../interfaces/Role.enum';
import { LanguageService } from '../services/language.service';
import { UserService } from '../services/user.service';

export const publicGuard: CanActivateFn = (route, state): Observable<boolean> => {
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);
  const languageService: LanguageService = inject(LanguageService);

  if (route.queryParamMap.get('lang')) {
    localStorage.setItem('selectedLanguage', route.queryParamMap.get('lang'));
    languageService.switchLanguage(route.queryParamMap.get('lang'));
  }

  return userService.currentUser$.pipe(
    take(1),
    map(user => {
      if (
        !user ||
        !user.isEmailVerified ||
        user.isBlocked ||
        (user &&
          user.role === Role.CLIENT &&
          (state.url.split('?')[0].replace('/', '') === NavigationPaths.LOGIN ||
            state.url.split('?')[0].replace('/', '') === NavigationPaths.REGISTRATION))
      ) {
        return true;
      }

      // TODO: Add logic for blocked users

      if (user && user.role === Role.ADMIN) {
        router.navigate([NavigationPaths.ADMIN_DASHBOARD]);
        return false;
      }

      if (user && user.role === Role.USER) {
        router.navigate([NavigationPaths.USER_DASHBOARD]);
        return false;
      }

      if (user && user.role === Role.CLIENT) {
        router.navigate([NavigationPaths.CLIENT_EVENTS]);
        return false;
      }

      router.navigate([NavigationPaths.LOGIN]);
      return false;
    })
  );
};
