import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DateRange } from '@angular/material/datepicker';
import firebase from 'firebase/compat/app';
import { customAlphabet } from 'nanoid';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subscription, first, firstValueFrom, map, of, switchMap } from 'rxjs';
import { DbCollections } from '../interfaces/Collections.enum';
import { DateSearchKeys, Event, EventImage, InputSearchKeys } from '../interfaces/Event.interface';
import { User } from '../interfaces/User.interface';
import { DatabaseService } from './database.service';
import { ImageUploadService } from './image-upload.service';
import { ShoppingCartService } from './shopping-cart.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private eventsSubject: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>([]);
  private eventImagesSubject: BehaviorSubject<EventImage[]> = new BehaviorSubject<EventImage[]>([]);
  private eventId: string;

  constructor(
    private db: DatabaseService,
    private firestore: AngularFirestore,
    private userService: UserService,
    private shoppingCartService: ShoppingCartService,
    private spinnerService: NgxSpinnerService,
    private imageUploadService: ImageUploadService
  ) {}

  public getEventImages(): Observable<EventImage[]> {
    return this.eventImagesSubject.asObservable();
  }

  public triggerEventsSubscription(): Subscription {
    this.spinnerService.show();
    return this.userService.currentUser$
      .pipe(
        switchMap(user => {
          if (user) {
            return this.getEventsForUser(user.uuid, user.deletedEvents);
          }

          return of([]);
        })
      )
      .subscribe((res: Event[]) => {
        res && res.length > 0 ? this.eventsSubject.next([...res]) : this.eventsSubject.next([]);
        this.spinnerService.hide();
      });
  }

  public triggerEventSubscription(): Subscription {
    this.spinnerService.show();
    return this.userService.currentUser$
      .pipe(
        switchMap(user => {
          return this.getEventsForUser(user.uuid, user.deletedEvents);
        })
      )
      .subscribe((res: Event[]) => {
        res ? this.eventsSubject.next([...res]) : this.eventsSubject.next([]);
        this.spinnerService.hide();
      });
  }

  public async addNewEvent(user: User, eventData: Event): Promise<boolean> {
    const userEventPath = `${DbCollections.EVENTS}/${user.uuid}/userEvents`;
    const { image, ...copyEvent } = eventData;

    try {
      const eventId = await this.db.createAndGetId(userEventPath, copyEvent);

      if (eventId) {
        let refId: string = '';
        let docExists = true;

        while (docExists) {
          refId = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6)();
          docExists = await new Promise((resolve, reject) => {
            this.firestore
              .collection('eventsList')
              .doc(refId)
              .get()
              .pipe(first())
              .subscribe({
                next: data => {
                  resolve(data.exists);
                },
                error: err => {
                  reject(err);
                },
              });
          });
        }

        await this.firestore
          .collection(DbCollections.EVENTS_LIST)
          .doc(refId)
          .set({ eventRef: this.firestore.doc(`${userEventPath}/${eventId}`).ref });

        if (image) {
          try {
            const resizedImageData = await firstValueFrom(this.imageUploadService.resizeImage(image, 270, 0.92));
            const imageURL = await this.db
              .uploadFile(resizedImageData.resizedImage, `${userEventPath}/${eventId}`)
              .then(res => res.ref.getDownloadURL());
            await this.db.update(userEventPath, eventId, { mainImage: imageURL, refId, id: eventId });
          } catch (err) {
            console.error('Image upload error:', err);
          }
        } else {
          await this.db.update(userEventPath, eventId, { refId, id: eventId });
        }

        const updatedEvents = {
          activeEvents: (Number(user.activeEvents) - 1).toString(),
          totalEvents: (Number(user.totalEvents || 0) + 1).toString(),
        };

        await this.db.update(DbCollections.USERS, user.uuid, updatedEvents);
        return true;
      }
    } catch (err) {
      console.error('Event addition error:', err);
    }

    return false;
  }

  public async editEvent(user: User, updatedEventData: Event): Promise<boolean> {
    const { image, id, ...copyEvent } = updatedEventData;

    if (image) {
      try {
        const uploadResult = await this.db.uploadFile(image, `${DbCollections.EVENTS}/${user.uuid}/userEvents/${id}`);
        const imageURL = await uploadResult.ref.getDownloadURL();
        copyEvent.mainImage = imageURL;
      } catch (err) {
        console.error(err);
        copyEvent.mainImage = copyEvent.mainImage || ''; // Use existing mainImage or set to empty string
      }
    }

    const res = await this.db.update(`${DbCollections.EVENTS}/${user.uuid}/userEvents`, id, copyEvent);

    if (res && copyEvent.saleEnd) {
      await this.db.set('eventsTrack', updatedEventData.refId, {
        uuid: user.uuid,
        eventId: id,
        saleEnd: copyEvent.saleEnd,
      });
    }

    return res;
  }

  public async deleteEvent(user: User, eventId: string): Promise<boolean> {
    const updatedDeletedEvents = [...(user.deletedEvents || []), eventId];
    return this.userService.update({ ...user, deletedEvents: updatedDeletedEvents });
  }

  public getEventsForUser(userUuid: string, excludeIds?: string[]): Observable<Event[]> {
    return this.firestore
      .collection(`${DbCollections.EVENTS}/${userUuid}/userEvents`)
      .snapshotChanges()
      .pipe(
        map((actions: any[]) =>
          actions.map(action => {
            const id = action.payload.doc.id;
            const data = action.payload.doc.data();
            return { id, ...data };
          })
        ),
        map(events => {
          if (excludeIds && excludeIds.length) {
            return events.filter(event => !excludeIds.includes(event.id));
          } else {
            return events;
          }
        })
      );
  }

  public getEvent(eventRefId: string): Observable<Event | boolean> {
    return this.db.getRefrences<object>(eventRefId).pipe(
      switchMap(res => {
        if (typeof res === 'boolean') {
          return of(false); // or any other placeholder value for boolean case
        } else {
          this.shoppingCartService.setTargetDbPath(`${res.path}/orders`);
          this.eventId = res.ref.id;
          return this.db.getAll(`${res.path}/images`).pipe(
            map(eventImages => {
              // TODO: Fix this interfaces
              this.eventImagesSubject.next(eventImages as EventImage[]);
              return res.ref as Event;
            })
          );
        }
      })
    );
  }

  public getEventAsObservable(eventRefId: string): Observable<Event | boolean> {
    return this.db.getRefrences<object>(eventRefId).pipe(
      switchMap(res => {
        if (typeof res === 'boolean') {
          return of(false);
        } else {
          this.db.getAll(`${res.path}/images`).subscribe(res => {
            this.eventImagesSubject.next(res as EventImage[]);
          });
          this.shoppingCartService.setTargetDbPath(`${res.path}/orders`);
          return this.firestore.doc<Event>(res.path).valueChanges();
        }
      })
    );
  }

  public async deleteImages(uuid: string, images: EventImage[], eventId?: string): Promise<boolean> {
    let res = true;
    for (let i = 0; i < images.length; i++) {
      const dbRes = await this.db
        .delete(`/events/${uuid}/userEvents/${eventId ? eventId : this.eventId}/images`, images[i].id)
        .then(async () => {
          return await this.db.deleteFile(images[i].url);
        })
        .catch(err => {
          console.error(err);
          return false;
        });
      res = dbRes && res;
    }
    return res;
  }

  public getEvents(): Observable<Event[]> {
    return this.eventsSubject.pipe(map(events => events.filter(event => !event.hasOwnProperty('saleEnd'))));
  }

  public getActiveEvents(): Observable<Event[]> {
    const currentTimestamp = firebase.firestore.Timestamp.fromDate(new Date()).toMillis();
    return this.eventsSubject.pipe(
      map(events =>
        events.filter(event => event.hasOwnProperty('saleEnd') && event.saleEnd.toMillis() > currentTimestamp)
      )
    );
  }

  public getInactiveEvents(): Observable<Event[]> {
    const currentTimestamp = firebase.firestore.Timestamp.fromDate(new Date()).toMillis();
    return this.eventsSubject.pipe(
      map(events =>
        events.filter(event => event.hasOwnProperty('saleEnd') && event.saleEnd.toMillis() <= currentTimestamp)
      )
    );
  }

  public filter(
    events: Event[],
    keyForDateSearch: DateSearchKeys,
    keyForInputSearch: InputSearchKeys,
    dateRange: DateRange<Date>,
    search: string = ''
  ): Event[] {
    let filteredEvents: Event[] = [...events];

    if (dateRange && dateRange.start && dateRange.end) {
      const startTimestamp = firebase.firestore.Timestamp.fromDate(dateRange.start).toMillis();
      const endTimestamp = firebase.firestore.Timestamp.fromDate(dateRange.end).toMillis();
      filteredEvents = filteredEvents.filter(
        event =>
          event[keyForDateSearch].toMillis() > startTimestamp && event[keyForDateSearch].toMillis() < endTimestamp
      );
    }

    if (!!search) {
      filteredEvents = filteredEvents.filter(event =>
        event[keyForInputSearch].toLowerCase().includes(search.toLowerCase())
      );
    }

    return filteredEvents;
  }
}
