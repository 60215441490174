import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { LoginPage } from 'src/app/definitions/PageLayout.definitions';
import { AuthPageLayout } from 'src/app/interfaces/PageLayout.interface';
import { Role } from 'src/app/interfaces/Role.enum';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  public loginPage: AuthPageLayout = LoginPage;
  private userSubscription?: Subscription;

  constructor(
    private auth: AuthenticationService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.userSubscription = this.userService.currentUser$.subscribe(async user => {
      if (user && user.role === Role.CLIENT) {
        await this.auth.logout();
      }

      this.spinner.hide();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
